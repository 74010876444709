import React from 'react';

const ResponseTable = ({ rows, responses }) => {
    return (
        <table className="response-table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Timeline</th>
                    <th>Activity</th>
                    <th>Deliverables</th>
                    <th>Means</th>
                    <th>Budget</th>
                    <th>File</th>
                </tr>
            </thead>
            <tbody>
                {rows.map((row) => (
                    <tr key={row.id}>
                        <td>{row.id}</td>
                        <td>{row.timeline}</td>
                        <td>{row.activity}</td>
                        <td>{row.deliverables}</td>
                        <td>{row.means}</td>
                        <td>{row.budget}</td>
                        <td>
                            {responses.filter(response => response.rowId === row.id).map((response, index) => (
                                <div key={index}>
                                    <p>File: <a href={`https://server.jodms.com/uploads/${response.file}`} target="_blank" rel="noopener noreferrer">{response.fileName}</a></p>
                                </div>
                            ))}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ResponseTable;
